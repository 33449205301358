import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css, ThemeContext } from 'styled-components';
import { renderStyledElementStyles } from '../../helpers';
import {
  CallToActionConfigProps,
  ImageTagConfigProps,
  SiteBuilderModule,
} from '../sharedComponents';

import {
  ImageBottomDisplayStyle,
  ImageLeftDisplayStyle,
  ImageRightDisplayStyle,
  ImageTopDisplayStyle,
} from './displayStyles';

const Wrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.siteBuilderModules.imageModule)}

  ${({ theme, visualStyle }) =>
    renderStyledElementStyles(
      theme,
      theme.siteBuilderModules.imageModule.visualStyles[visualStyle],
    )}
    
   ${({ theme, textStyles }) => {
    return `
      p, h2 {
        ${renderStyledElementStyles(theme, textStyles)}
      }
    `;
  }}
  
  ${({ theme }) =>
    theme.siteBuilderModules.imageModule.settings
      ?.reduceGutterBetweenSameSections &&
    css`
      .image-section + .image-section & {
        padding-top: 0;
      }
    `}
`;

const ImageModule = (props) => {
  const {
    section: {
      backgroundColour: visualStyle,
      imagePosition: displayStyle,
      textColour = '',
    },
  } = props;

  const themeConfig = useContext(ThemeContext);

  const displayStyles =
    themeConfig.siteBuilderModules.imageModule.displayStyles[displayStyle];
  const visualStyles =
    themeConfig.siteBuilderModules.imageModule.visualStyles[visualStyle];
  const textStyles =
    themeConfig.siteBuilderModules.imageModule.textStyles[textColour];

  return (
    <SiteBuilderModule section={props.section}>
      <Wrapper visualStyle={visualStyle} textStyles={textStyles}>
        {displayStyle === 'TOP' && (
          <ImageTopDisplayStyle
            section={props.section}
            displayStyles={displayStyles}
            visualStyles={visualStyles}
          />
        )}
        {displayStyle === 'RIGHT' && (
          <ImageRightDisplayStyle
            section={props.section}
            displayStyles={displayStyles}
            visualStyles={visualStyles}
          />
        )}
        {displayStyle === 'BOTTOM' && (
          <ImageBottomDisplayStyle
            section={props.section}
            displayStyles={displayStyles}
            visualStyles={visualStyles}
          />
        )}
        {displayStyle === 'LEFT' && (
          <ImageLeftDisplayStyle
            section={props.section}
            displayStyles={displayStyles}
            visualStyles={visualStyles}
          />
        )}
      </Wrapper>
    </SiteBuilderModule>
  );
};

ImageModule.propTypes = {
  section: PropTypes.shape({
    backgroundColour: PropTypes.oneOf([
      'PRIMARY',
      'SECONDARY',
      'ACCENT',
      'WHITE',
      'BLACK',
      'GREY',
    ]).isRequired,
    textStyle: PropTypes.oneOf([
      'PRIMARY',
      'SECONDARY',
      'ACCENT',
      'DEFAULT',
      'INVERTED',
      'GREY',
    ]).isRequired,
    imagePosition: PropTypes.oneOf(['TOP', 'RIGHT', 'BOTTOM', 'LEFT'])
      .isRequired,
    image: ImageTagConfigProps.isRequired,
    title: PropTypes.string,
    text: PropTypes.string,
    link: CallToActionConfigProps,
  }).isRequired,
};

export default ImageModule;
